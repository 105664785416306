<template lang="pug">
  div
    v-row
      v-col(cols="3")
        create-order-detail-status
      v-col(cols="9")
        table-order-detail-status
</template>
<script>
export default {
  name: 'OrderDetailStatus',
  components: {
    createOrderDetailStatus: () => import('./Create'),
    tableOrderDetailStatus: () => import('./Table'),
  },
}
</script>